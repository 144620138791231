import axios from "axios";
import React, { createContext, useCallback, useContext, useState } from "react";
import { AuthContext } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";

interface INotifticationCollection<D> {
  initialized: boolean;
  data: D;
}

interface IPagination<D> {
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  recordPerPage: number;
  records: D[];
}

interface INotificationData {
  _id: string;
  title: string;
  message: string;
  priority: "HIGH" | "MEDIUM" | "LOW";
  sentAt: string;
  readBy: string[] | undefined;
}

interface INotificationGroup {
  _id: string;
  groupName: string;
  description: string;
  adminIds: string[];
  locked: boolean;
  groupOwner: string[];
  channelLine: boolean;
  channelLineApi: string;
  channelTelegram: boolean;
  channelTelegramApi: string;
  notificationEvents: string[];
  allowSubscription: boolean;
  approvalPermissions: string[];
}

interface INotifticationContext {
  collection: {
    unread: INotifticationCollection<{
      count: number;
      notifications: INotificationData[];
    }>;
    list: INotifticationCollection<
      IPagination<
        INotificationData & {
          groupInfo: INotificationGroup[];
        }
      >
    >;
  };
  getUnread: (force?: boolean, show_loading?: boolean) => Promise<void>;
  getList: (page: Record<string, any>, force?: boolean) => Promise<void>;
  createNotify: (payload: Record<string, any>) => Promise<void>;
  setReadedNotification: (notificationId: string) => Promise<void>;
}

export const NotifticationServiceContext =
  createContext<INotifticationContext | null>(null);

export default function NotifticationService({
  children,
}: {
  children: React.ReactNode;
}) {
  const authContext = useContext(AuthContext);

  const loading = useLoading();

  const [collectionUnread, setCollectionUnread] = useState<
    INotifticationContext["collection"]["unread"]
  >({
    initialized: false,
    data: {
      count: -1,
      notifications: [],
    },
  });

  const [collectionList, setCollectionList] = useState<
    INotifticationContext["collection"]["list"]
  >({
    initialized: false,
    data: {
      currentPage: 0,
      recordPerPage: 0,
      totalPages: 0,
      totalRecords: 0,
      records: [],
    },
  });

  const onFetch = useCallback(
    async (
      url: string,
      body: Record<string, string | number>,
      show_loading: boolean = true,
    ) => {
      if (show_loading) {
        loading.push();
      }
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_URL}${url}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${authContext.token}`,
            },
            validateStatus: () => true,
          },
        );

        return resp.data;
      } catch (e) {
        return {};
      } finally {
        if (show_loading) {
          loading.pop();
        }
      }
    },
    [authContext],
  );

  async function getUnread(force = false, show_loading = true) {
    if (!collectionUnread.initialized || force) {
      const { data } = await onFetch(
        "/api/v1/notification/unread-notifications",
        {},
        show_loading,
      );
      if (data) {
        setCollectionUnread({
          initialized: true,
          data,
        });
      }
    }
  }

  async function getList(payload: Record<string, any>, force = false) {
    if (!collectionList.initialized || force) {
      const { data } = await onFetch("/api/v1/notification/list", payload);
      if (data) {
        setCollectionList({
          initialized: true,
          data,
        });
      }
    }
  }

  async function createNotify(payload: Record<string, any>) {
    await onFetch("/api/v1/notification/create", payload);
  }

  async function setReadedNotification(notificationId: string) {
    await onFetch("/api/v1/notification/read-notifications", {
      notificationId,
    });
  }

  return (
    <NotifticationServiceContext.Provider
      value={{
        collection: {
          unread: collectionUnread,
          list: collectionList,
        },
        getUnread,
        getList,
        createNotify,
        setReadedNotification,
      }}
    >
      {children}
    </NotifticationServiceContext.Provider>
  );
}
