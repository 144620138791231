import { lazy } from "react";
import { Outlet } from "react-router";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const DistributorProvider = ScreenLoader(
  lazy(() => import("../screens/distributor-screen/DistributorProvider")),
);

export const DistribustorRoute = () => ({
  path: "distributor",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [{ path: "providers", element: <DistributorProvider /> }],
});
