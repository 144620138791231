import { lazy } from "react";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";
import ActivityLogsService from "../contexts/services/activity-logs-service/ActivityLogsService";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const ActivityLogs = ScreenLoader(
  lazy(() => import("../screens/activity-logs/ActivityLogsScreen")),
);

export const AcitiviyLogsRoute = () => ({
  path: "activity-logs",
  element: (
    <AuthGuard>
      <RoleGuard requiredPermission="adminLog" requiredActions={["isRead"]}>
        <ActivityLogsService>
          <Outlet />
        </ActivityLogsService>
      </RoleGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <ActivityLogs />,
    },
  ],
});
