import { InfinitySpin } from "react-loader-spinner";

function LoadingPage() {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <InfinitySpin width="200" color="#3f2df1" />
      <h1 className="text-2xl text-indigo-500">Wait a sec...</h1>
    </div>
  );
}
export default LoadingPage;
