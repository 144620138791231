import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LoadingProvider } from "./contexts/LoadingContext";
import { ModalProvider } from "./contexts/ModalContext";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterHOC } from "./routes";
import { I18nextProvider } from "react-i18next";
// import i18n (needs to be bundled ;))
import i18n from "./i18n";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <LoadingProvider>
          <ModalProvider>
            <RouterHOC />
          </ModalProvider>
        </LoadingProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);

reportWebVitals();
