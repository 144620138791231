import { lazy } from "react";
import { Outlet } from "react-router";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

//Marketing
const MarketingAdd = ScreenLoader(
  lazy(() => import("../screens/marketing-screen/MarketingAdd")),
);
const MarketingManagement = ScreenLoader(
  lazy(() => import("../screens/marketing-screen/MarketingManagement")),
);

export const MarketingRoute = () => ({
  path: "marketing",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    { path: "marketing-add", element: <MarketingAdd /> },
    { path: "marketing-management", element: <MarketingManagement /> },
  ],
});
