import axios from "axios";
import { createContext, FC, useCallback, useContext, useState } from "react";
import { AuthContext } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";

type AdminListQuery = {
  page: number;
  size: number;
  username: string;
};

type AdminListServiceContextProps = {
  query: AdminListQuery;
  collection: any[];
  roles: { _id: string; roleName: string }[];
  fetchRoles: Function;
  onFetch: Function;
  setCollection: (collection: any[]) => void;
  updateAdminRole: (id: string, roleId: string) => Promise<void>;
};

const defaultContext: AdminListServiceContextProps = {
  query: {
    page: 1,
    size: 10,
    username: "",
  },
  collection: [],
  roles: [],
  fetchRoles: () => {},
  onFetch: () => {},
  setCollection: () => {},
  updateAdminRole: async () => {},
};

const getServiceEndpoint = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/admin/list`;

const getRolesEndpoint = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/role/list`;

export const AdminListServiceContext = createContext(defaultContext);

export const AdminListServiceContextProvider: FC<any> = ({ children }) => {
  const [collection, setCollection] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);

  const [query] = useState<AdminListQuery>(defaultContext.query);
  const authContext = useContext(AuthContext);
  const loading = useLoading();

  const onFetch = useCallback(
    async (data: AdminListQuery) => {
      loading.push();
      try {
        const response = await axios.post(getServiceEndpoint(), data || query, {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
        });

        if (response.data) {
          setCollection(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching collection:", error);
      } finally {
        loading.pop();
      }
    },
    [query, authContext, loading],
  );

  const fetchRoles = useCallback(async () => {
    try {
      const response = await axios.post(
        getRolesEndpoint(),
        {},
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
        },
      );
      const roles = response.data.data.map((role: any) => ({
        _id: role._id,
        roleName: role.roleName,
      }));
      setRoles(roles);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  }, [authContext.token]);

  const updateAdminRole = useCallback(
    async (id: string, roleId: string) => {
      try {
        const response = await axios.put(
          `${getServiceEndpoint()}/${id}/role`,
          { roleId },
          {
            headers: {
              Authorization: `Bearer ${authContext.token}`,
            },
          },
        );
      } catch (error) {
        console.error("Error updating admin role:", error);
      }
    },
    [authContext.token],
  );

  return (
    <AdminListServiceContext.Provider
      value={{
        query,
        collection,
        roles,
        fetchRoles,
        onFetch,
        setCollection,
        updateAdminRole,
      }}
    >
      {children}
    </AdminListServiceContext.Provider>
  );
};
