import { lazy } from "react";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const BetReport = ScreenLoader(lazy(() => import("../screens/BetReport")));

export const BetReportRoute = () => ({
  path: "bet-report",
  element: (
    <AuthGuard>
      <RoleGuard requiredPermission="betReport" requiredActions={["isRead"]}>
        <BetReport />
      </RoleGuard>
    </AuthGuard>
  ),
});
