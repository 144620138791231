import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { InfinitySpin } from "react-loader-spinner";
import PageNotFound from "../utils/pageNoFound";
import { SelfContext } from "./SelfContext";

interface IRoleGuardProps {
  requiredPermission?: string;
  requiredActions: Array<keyof PermissionDetail | undefined>;
  children: React.ReactNode;
  isShowPermissionDenies?: boolean;
}

interface PermissionDetail {
  isRead: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}

interface Permission {
  [key: string]: PermissionDetail;
}

export const RoleGuard: FC<IRoleGuardProps> = ({
  requiredPermission,
  requiredActions,
  children,
  isShowPermissionDenies = true,
}) => {
  const { user, isFetched, isLoading, onSyncSelf } = useContext(SelfContext);
  const [hasPermission, setHasPermission] = useState<boolean | undefined>(
    undefined,
  );
  const [isCheckingPermission, setIsCheckingPermission] = useState(true);

  useEffect(() => {
    if (!isFetched) onSyncSelf();
  }, [isFetched, onSyncSelf]);

  const shouldFetchPermission = useRef(true);
  const syncUserData = useCallback(async () => {
    if (hasPermission === undefined) {
      if (!requiredPermission) {
        setIsCheckingPermission(false);
        return;
      }

      const permissions = (user?.role?.permission as Permission) || {};
      const permissionDetails = permissions[requiredPermission] || {};
      const hasAllPermissions = requiredActions?.every(
        (action: keyof PermissionDetail | undefined) =>
          action ? permissionDetails[action] : false,
      );
      setHasPermission(hasAllPermissions);
      setIsCheckingPermission(false);
      shouldFetchPermission.current = false;
    }
  }, [hasPermission, requiredActions, requiredPermission, user?.role]);
  useEffect(() => {
    if (isFetched) {
      syncUserData();
    }
  }, [isFetched, syncUserData]);

  if (isLoading || !user || isCheckingPermission) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <InfinitySpin color="blue" />
      </div>
    );
  }

  if (!requiredPermission) {
    return <>{children}</>;
  }

  if (hasPermission) {
    return <>{children}</>;
  } else {
    return isShowPermissionDenies ? <PageNotFound /> : null;
  }
};
