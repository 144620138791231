import { lazy } from "react";
import { Navigate } from "react-router";
import { ScreenLoader } from "../utils/ScreenLoader";

const PageNotFound = ScreenLoader(
  lazy(() => import("../../src/screens/PageNotFound")),
);
export const DefaultRoute = () => ({
  path: "*",
  children: [
    { path: "404", element: <PageNotFound /> },
    { path: "*", element: <Navigate to="/404" replace /> },
  ],
});
