import { lazy } from "react";
import { Outlet } from "react-router";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const Agent = ScreenLoader(lazy(() => import("../screens/agent-screen/Agent")));
const AgentAdd = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentAdd")),
);
const AgentCredit = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentCredit")),
);
const AgentManagement = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentManagement")),
);
const AgentTransaction = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentTransaction")),
);
const AgentTransactionDaily = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentTransactionDaily")),
);

export const AgentManagementRoute = () => ({
  path: "agent",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    { path: "my-agent", element: <Agent /> },
    { path: "agent-add", element: <AgentAdd /> },
    { path: "agent-credit", element: <AgentCredit /> },
    { path: "agent-management", element: <AgentManagement /> },
    { path: "agent-report", element: <AgentTransaction /> },
    { path: "agent-report-daily", element: <AgentTransactionDaily /> },
  ],
});
