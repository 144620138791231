import { useContext, useEffect, useRef } from "react";
import { Navigate } from "react-router";
import { AuthContext } from "../contexts/AuthContext";
import { SelfContextProvider } from "../contexts/SelfContext";

export const AuthGuard = ({ children }: { children: React.ReactNode }) => {
  const contextAuth = useContext(AuthContext);

  const storedToken = localStorage.getItem("NAGA_ADMIN:token");
  const shouldCheckToken = useRef(true);

  useEffect(() => {
    if (storedToken && shouldCheckToken.current) {
      contextAuth.onUpdateToken(storedToken);
      shouldCheckToken.current = false;
    }
  }, [contextAuth, shouldCheckToken, storedToken]);

  if (storedToken != null && storedToken) {
    return (
      <SelfContextProvider>
        <>{children}</>
      </SelfContextProvider>
    );
  } else {
    return <Navigate to="/" replace />;
  }
};
