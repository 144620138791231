import axios from "axios";
import React, { createContext, useCallback, useContext, useState } from "react";
import { AuthContext } from "../../AuthContext";
import { useLoading } from "../../LoadingContext";

interface INotifticationGroupCollection<D> {
  initialized: boolean;
  data: D;
}

interface INotificationGroup {
  _id: string;
  groupName: string;
  description: string;
  adminIds: string[];
  locked: boolean;
  groupOwner: string[];
  channelLine: boolean;
  channelLineApi: string;
  channelTelegram: boolean;
  channelTelegramApi: string;
  notificationEvents: string[];
  allowSubscription: boolean;
  approvalPermissions: string[];
}

interface IPagination<D> {
  totalRecords: number;
  totalPages: number;
  currentPage: number;
  recordPerPage: number;
  records: D[];
}

interface INotificationGroupInfo extends Omit<INotificationGroup, "adminIds"> {
  adminInfo: any[];
}

interface INotifticationGroupContext {
  collection: {
    list: INotifticationGroupCollection<IPagination<INotificationGroup>>;
    events: INotifticationGroupCollection<{ events: string[] }>;
    info: INotifticationGroupCollection<INotificationGroupInfo | null>;
  };

  getList: (page: Record<string, any>, force?: boolean) => Promise<void>;
  createGroup: (payload: Record<string, any>) => Promise<void>;
  getGroup: (id: string, force?: boolean) => Promise<void>;
  updateGroup: (id: string, payload: Record<string, any>) => Promise<void>;
  toggleLockGroup: (id: string) => Promise<void>;

  getNotificationEventsAvaliable: () => Promise<void>;
}

export const NotifticationGroupServiceContext =
  createContext<INotifticationGroupContext | null>(null);

export default function NotifticationGroupService({
  children,
}: {
  children: React.ReactNode;
}) {
  const authContext = useContext(AuthContext);
  const loading = useLoading();

  const [collectionList, setCollectionList] = useState<
    INotifticationGroupContext["collection"]["list"]
  >({
    initialized: false,
    data: {
      currentPage: 0,
      recordPerPage: 0,
      totalPages: 0,
      totalRecords: 0,
      records: [],
    },
  });
  const [collectionNotifiactionEvents, setCollectionNotificationEvents] =
    useState<INotifticationGroupContext["collection"]["events"]>({
      initialized: false,
      data: {
        events: [],
      },
    });
  const [collectionInfo, setCollectionInfo] = useState<
    INotifticationGroupContext["collection"]["info"]
  >({
    initialized: false,
    data: null,
  });

  const onFetch = useCallback(
    async (url: string, body: Record<string, string | number>) => {
      loading.push();
      try {
        const resp = await axios.post(
          `${process.env.REACT_APP_API_URL}${url}`,
          body,
          {
            headers: {
              Authorization: `Bearer ${authContext.token}`,
            },
            validateStatus: () => true,
          },
        );

        return resp.data;
      } catch (e) {
        return {};
      } finally {
        loading.pop();
      }
    },
    [authContext],
  );

  async function getList(payload: Record<string, any>, force = false) {
    if (!collectionList.initialized || force) {
      const { data } = await onFetch("/api/v1/group/list", payload);
      if (data) {
        setCollectionList({
          initialized: true,
          data,
        });
      }
    }
  }

  async function getGroup(id: string, force = false) {
    if (!collectionInfo.initialized || force) {
      const { data } = await onFetch(`/api/v1/group/get/${id}`, {});
      if (data) {
        setCollectionInfo({
          initialized: true,
          data,
        });
      }
    }
  }

  async function getNotificationEventsAvaliable() {
    if (!collectionNotifiactionEvents.initialized) {
      const { data } = await onFetch(
        `/api/v1/group/notification-event-list`,
        {},
      );
      if (data) {
        setCollectionNotificationEvents({
          initialized: true,
          data,
        });
      }
    }
  }

  async function createGroup(payload: Record<string, string>) {
    await onFetch("/api/v1/group/create", payload);
  }

  async function updateGroup(id: string, payload: Record<string, string>) {
    await onFetch(`/api/v1/group/update/${id}`, payload);
  }

  async function toggleLockGroup(id: string) {
    await onFetch(`/api/v1/group/toggle-locked/${id}`, {});
  }

  return (
    <NotifticationGroupServiceContext.Provider
      value={{
        collection: {
          list: collectionList,
          events: collectionNotifiactionEvents,
          info: collectionInfo,
        },
        getList,
        getGroup,
        createGroup,
        updateGroup,
        toggleLockGroup,
        getNotificationEventsAvaliable,
      }}
    >
      {children}
    </NotifticationGroupServiceContext.Provider>
  );
}
