// src/i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: "th", // Fallback language
    supportedLngs: ["th", "en"], // Supported languages
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
    detection: {
      // Configure language detection options
      order: ["localStorage"],
      caches: ["localStorage"], // Where to store the detected language
    },
  });

export default i18n;
