import { useNavigate } from "react-router-dom";
import svg from "../assets/404.svg";

const PageNotFound = () => {
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate("/dashboard");
  };

  return (
    <div className="flex h-screen flex-col items-center justify-center text-center">
      <img
        src={svg}
        alt="ไม่มีสิทธิ์เข้าถึง"
        className="h-auto w-1/2 max-w-xs md:max-w-md"
      />
      <button
        onClick={handleBackToDashboard}
        className="mt-5 rounded bg-black px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-500"
      >
        กลับสู่หน้าหลัก
      </button>
    </div>
  );
};

export default PageNotFound;
