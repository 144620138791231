import axios from "axios";
import { createContext, FC, useCallback, useContext, useState } from "react";
import { AuthContext } from "./AuthContext";

interface IOtpContext {
  token: string;
  tokenURL: string;
  error: string;
  isProcessing: boolean;
  setToken: Function;
  onCreate: () => Promise<void>;
  onVerify: () => Promise<boolean>;
}
const otpContextDefault: IOtpContext = {
  token: "",
  tokenURL: "",
  error: "",
  isProcessing: false,
  setToken: () => {},
  onCreate: () => new Promise(async () => {}),
  onVerify: () => new Promise(async () => false),
};

export const createOtp = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/otp/admin/create`;
export const verifyOTP = () =>
  `${process.env.REACT_APP_API_URL}/api/v1/otp/admin/verify`;

export const OtpContext = createContext(otpContextDefault);
export const OtpContextProvider: FC<any> = ({ children }) => {
  const [token, setToken] = useState(otpContextDefault.token);
  const [tokenURL, setTokenURL] = useState(otpContextDefault.tokenURL);
  const [error, setError] = useState(otpContextDefault.error);
  const [isProcessing, setIsProcessing] = useState(false);
  const authContext = useContext(AuthContext);

  const onCreate = useCallback(async () => {
    setIsProcessing(true);
    setError("");

    try {
      const response = await axios.post(
        createOtp(),
        {},
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
        },
      );
      if (response.data?.data) setTokenURL(response.data.data);
      if (response.data?.error) setError(response.data.error);
    } catch (error) {
      setError("กรุณาลงชื่อเข้าใช้ใหม่");
    } finally {
      setIsProcessing(false);
    }
  }, [authContext.token]);

  const onVerify = useCallback(async () => {
    setIsProcessing(true);
    setError("");
    try {
      const response = await axios.post(
        verifyOTP(),
        { token },
        {
          headers: {
            Authorization: `Bearer ${authContext.token}`,
          },
        },
      );
      if (response.data?.data) {
        authContext.onUpdateToken(response.data.data);
        return true;
      }
      if (response.data?.error) setError(response.data.error.message);
      return false;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  }, [authContext, token]);

  return (
    <OtpContext.Provider
      value={{
        token,
        tokenURL,
        error,
        isProcessing,
        setToken,
        onCreate,
        onVerify,
      }}
    >
      {children}
    </OtpContext.Provider>
  );
};
