import { lazy } from "react";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const Reconcile = ScreenLoader(
  lazy(() => import("../screens/reconcile-screen/ReconcileAll")),
);
//! This is a route for reconciling transactions not sure is used or not?????
export const ReconcileRoute = () => ({
  path: "reconcile",
  element: (
    <AuthGuard>
      <RoleGuard requiredPermission="reconcile" requiredActions={["isRead"]}>
        <Reconcile />
      </RoleGuard>
    </AuthGuard>
  ),
});
