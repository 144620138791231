import { useRoutes } from "react-router";
import { AuthContextProvider } from "../contexts/AuthContext";
import { AcitiviyLogsRoute } from "./ActivityLogs";
import { AffiliatePromotionRoute } from "./AffiliatePromotion";
import { AgentManagementRoute } from "./AgentManagementRoute";
import { AnnoucementRoute } from "./AnnouncementRoute";
import { BetReportRoute } from "./BetReportRoute";
import { CreditTrancsactionRoute } from "./CreditTrancsactionRoute";
import { DashboardRoute } from "./DashboardRoute";
import { DefaultRoute } from "./DefaultRoute";
import { DistribustorRoute } from "./DistribustorRoute";
import { EmployeeRoute } from "./EmployeeRoute";
import { MarketingRoute } from "./MarketingRoute";
import { MemberRoute } from "./MemberRoute";
import { NotificationGroupRoute } from "./NotificationGroupRoute";
import { NotificationRoute } from "./NotificationRoute";
import { OtpRoute } from "./OtpRoute";
import { PaymentAutoRoute } from "./PaymentAutoRoute";
import { PaymentGatewayRoute } from "./PaymentGatewayRoute";
import { PreTransactionRoute } from "./PreTransactionRoute";
import { PromotionRoute } from "./PromotionRoute";
import { ReconcileRoute } from "./ReconcileRoute";
import { RefundPromotionRoute } from "./RefundPromotionRoute";
import { ReportRoute } from "./ReportRoute";
import { RootRoute } from "./RootRoute";
import { SellerRoute } from "./SellerRoute";
import { SettingRoute } from "./SettingRoute";
import { WebsiteRoute } from "./WebsiteRoute";

export default function Router() {
  return useRoutes([
    RootRoute(),
    OtpRoute(),
    DefaultRoute(),
    DashboardRoute(),
    MemberRoute(),
    SellerRoute(),

    PreTransactionRoute(),
    ReconcileRoute(),

    PromotionRoute(),
    RefundPromotionRoute(),
    AffiliatePromotionRoute(),
    MarketingRoute(),

    AgentManagementRoute(),
    EmployeeRoute(),
    AnnoucementRoute(),

    ReportRoute(),
    CreditTrancsactionRoute(),
    BetReportRoute(),

    MarketingRoute(),
    SettingRoute(),
    PaymentGatewayRoute(),
    PaymentAutoRoute(),
    WebsiteRoute(),
    DistribustorRoute(),

    NotificationRoute(),
    NotificationGroupRoute(),

    AcitiviyLogsRoute(),
  ]);
}

export const RouterHOC = () => {
  return (
    <AuthContextProvider>
      <Router />
    </AuthContextProvider>
  );
};
