import { lazy } from "react";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const Dashboard = ScreenLoader(lazy(() => import("../screens/Dashboard")));

//maping the dashboard route completely
export const DashboardRoute = () => ({
  path: "dashboard",
  element: (
    <AuthGuard>
      <RoleGuard requiredPermission="dashboard" requiredActions={["isRead"]}>
        <Dashboard />
      </RoleGuard>
    </AuthGuard>
  ),
});
