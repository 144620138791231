import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from "react";
import { Actions, AppAbility, defineAbilitiesFor } from "./abilities";
import LoadingPage from "../screens/LoadingPage";
import { Permissions } from "../interfaces/permission.interface";
import { Subjects } from "../constant/permission.constant";
import PageNotFound from "../utils/pageNoFound";
import { useSelf } from "./SelfContext";

interface AbilityProviderProps {
  children: ReactNode;
  allowActions?: Actions[];
  allowSubjects?: Subjects;
  isAdmin?: boolean;
}

const AbilityContext = createContext<AppAbility | null>(null);

export const AbilityProvider: React.FC<AbilityProviderProps> = ({
  children,
  allowActions,
  isAdmin,
  allowSubjects,
}) => {
  const [ability, setAbility] = useState<AppAbility | null>(null);
  const { user, isLoading, onSyncSelf } = useSelf();
  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        if (isLoading) return;
        await onSyncSelf();
        const permissions: Permissions = user?.permissions as Permissions;
        setAbility(defineAbilitiesFor(permissions, isAdmin));
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, [isLoading, onSyncSelf, isAdmin]);

  if (isLoading || !ability) {
    return <LoadingPage />;
  }

  const renderWithContext = (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
  //ถ้า 1 ในนั้น allow สามารถเข้าหน้านั้นได้เลย
  if (allowActions && allowSubjects && ability) {
    const hasPermission = allowActions.some((item) =>
      ability.can(item, allowSubjects),
    );

    return hasPermission ? renderWithContext : <PageNotFound />;
  }

  return renderWithContext;
};

export const useAbility = (): AppAbility => {
  const ability = useContext(AbilityContext);
  if (!ability) {
    throw new Error("useAbility must be used within an AbilityProvider");
  }
  return ability;
};
