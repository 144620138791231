// abilities.ts
import { Ability, AbilityBuilder, InferSubjects } from "@casl/ability";
import { Subjects } from "../constant/permission.constant";
import { Permissions } from "../interfaces/permission.interface";

export type Actions = "read" | "create" | "update" | "delete" | "manage";
type InferredSubjects = InferSubjects<Subjects | "all">; // Inferred subjects

export type AppAbility = Ability<[Actions, InferredSubjects]>;

export const defineAbilitiesFor = (
  permissions: Permissions,
  isAdmin?: boolean,
): AppAbility => {
  const { can, rules } = new AbilityBuilder<AppAbility>(Ability as any);
  if (isAdmin) {
    can("manage", "all");
  } else {
    Object.keys(permissions).forEach((resource) => {
      const actions = permissions[resource];
      if (actions.isRead) can("read", resource as Subjects);
      if (actions.isCreate) can("create", resource as Subjects);
      if (actions.isUpdate) can("update", resource as Subjects);
      if (actions.isDelete) can("delete", resource as Subjects);
    });
  }

  return new Ability(rules) as AppAbility;
};
