import axios from "axios";
import { createContext, FC, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

interface PermissionDetail {
  isRead: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}
interface Permission {
  [key: string]: PermissionDetail;
}

interface Role {
  permission: Permission;
}

interface WalletAdmin {
  finalBalance: number;
}

interface User {
  username: string;
  type: string;
  id: string;
  roleName?: string;
  role: Role | null;
  permissions?: Permission;
  wallet: WalletAdmin | null;
}

export const SelfContext = createContext({
  user: {
    username: "",
    type: "",
    id: "",
    roleName: undefined,
    role: null,
    wallet: null,
    permissions: {},
  } as User,
  isLoading: true,
  isFetched: false,
  onSyncSelf: () => {},
});

export const SelfContextProvider: FC<any> = ({ children }) => {
  const navigation = useNavigate();
  const contextAuth = useContext(AuthContext);
  const [user, setUser] = useState({
    username: "",
    type: "",
    id: "",
    roleName: undefined,
    role: null,
    wallet: null,
    permissions: {} as Permission,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [isFetched, setIsFetched] = useState(false);

  const onSyncSelf = useCallback(async () => {
    try {
      const token = contextAuth.token;

      if (!token) {
        setIsLoading(false);
        return;
      }

      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/authorization/self`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userData = {
        ...request.data.data,
        roleName: request.data.data.role.roleName,
        role: request.data.data.role,
        permissions: request.data.data.role?.permission,
      };

      setUser(userData);
      setIsFetched(true);
    } catch (error) {
      const response = (error as any).response;
      console.error(error);
      if (
        (response && response.status === 401) ||
        (response && response.status === 500)
      ) {
        contextAuth.onReset();
        localStorage.removeItem("NAGA_ADMIN:token");
        navigation("/");
      }
      console.error("Unexpected error", error);
    } finally {
      setIsLoading(false);
    }
  }, [contextAuth, navigation]);

  return (
    <SelfContext.Provider value={{ user, isFetched, isLoading, onSyncSelf }}>
      {children}
    </SelfContext.Provider>
  );
};

export const useSelf = () => {
  const context = useContext(SelfContext);
  if (!context) {
    throw new Error("useSelf must be used within SelfContextProvider");
  }
  return context;
};
