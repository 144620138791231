import { lazy } from "react";
import { OtpContextProvider } from "../contexts/OtpContext";
import { SelfPreOtpContextProvider } from "../contexts/SelfPreOtpContext";
import { ScreenLoader } from "../utils/ScreenLoader";

const LoginOtpPage = ScreenLoader(
  lazy(() => import("../screens/LoginOtpPage")),
);

export const OtpRoute = () => ({
  path: "otp",
  element: (
    <SelfPreOtpContextProvider>
      <OtpContextProvider>
        <LoginOtpPage />
      </OtpContextProvider>
    </SelfPreOtpContextProvider>
  ),
});
