import { lazy } from "react";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

const AgentReport = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentReport")),
);

const AgentDailyReport = ScreenLoader(
  lazy(() => import("../screens/agent-screen/AgentDailyReport")),
);

export const ReportRoute = () => ({
  path: "report",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "total-user",
      element: (
        <RoleGuard
          requiredPermission="agentReport"
          requiredActions={["isRead"]}
        >
          <AgentReport />
        </RoleGuard>
      ),
    },
    {
      path: "total-daily",
      element: (
        <RoleGuard
          requiredPermission="agentReport"
          requiredActions={["isRead"]}
        >
          <AgentDailyReport />
        </RoleGuard>
      ),
    },
  ],
});
