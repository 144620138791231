import { lazy } from "react";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

//Website
const PaymentGatewayAutoList = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoList")),
);
const PaymentGatewayAutoTransactions = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoTransactions"),
  ),
);
const PaymentGatewayAutoCreate = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoCreate")),
);
const PaymentGatewayAutoTransfer = ScreenLoader(
  lazy(() => import("../screens/payment-screen/PaymentGatewayAutoTransfer")),
);
const PaymentGatewayAutoApproveTransfer = ScreenLoader(
  lazy(
    () => import("../screens/payment-screen/PaymentGatewayAutoApproveTransfer"),
  ),
);

export const PaymentAutoRoute = () => ({
  path: "payment-auto",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "list",
      element: (
        <RoleGuard
          requiredPermission="paymentAuto"
          requiredActions={["isRead"]}
        >
          <PaymentGatewayAutoList />
        </RoleGuard>
      ),
    },
    {
      path: "transactions/:id",
      element: (
        <RoleGuard
          requiredPermission="paymentAuto"
          requiredActions={["isRead"]}
        >
          <PaymentGatewayAutoTransactions />
        </RoleGuard>
      ),
    },
    {
      path: "add",
      element: (
        <RoleGuard
          requiredPermission="paymentAuto"
          requiredActions={["isCreate"]}
        >
          <PaymentGatewayAutoCreate />
        </RoleGuard>
      ),
    },
    {
      path: "transfer",
      element: (
        <RoleGuard
          requiredPermission="paymentAutoTransfer"
          requiredActions={["isCreate"]}
        >
          <PaymentGatewayAutoTransfer />
        </RoleGuard>
      ),
    },
    {
      path: "approve-transfer",
      element: (
        <RoleGuard
          requiredPermission="paymentAutoTransfer"
          requiredActions={["isUpdate"]}
        >
          <PaymentGatewayAutoApproveTransfer />
        </RoleGuard>
      ),
    },
  ],
});
