import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, Modal } from "@mui/material";
import { createContext, useCallback, useContext, useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 2,
};

export interface ModalOptions {
  title: string;
  children: React.ReactNode;
}

interface ModalContextProps {
  openModal: (modalOptions: ModalOptions) => void;
  closeModal: () => void;
}

const ModalContext = createContext<ModalContextProps>({
  openModal: () => {},
  closeModal: () => {},
});

export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<React.ReactNode>(null);

  const openModal = useCallback((modalOptions: ModalOptions) => {
    setTitle(modalOptions.title);
    setContent(modalOptions.children);
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setTitle("");
    setContent(null);
    setOpen(false);
  }, []);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between pb-1">
              <h3 className="text-lg font-semibold ">{title}</h3>
              <XMarkIcon className="h-4 w-4" onClick={closeModal} />
            </div>
            <div>{content}</div>
          </div>
        </Box>
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

export function useModal() {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within ModalProvider");
  }
  return context;
}
