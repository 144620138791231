import axios from "axios";
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./AuthContext";

interface PermissionDetail {
  isRead: boolean;
  isCreate: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}
interface Permission {
  [key: string]: PermissionDetail;
}

interface Role {
  permission: Permission;
}

interface User {
  username: string;
  type: string;
  id: string;
  roleName?: string;
  role: Role | null;
  permissions?: Permission;
}

export const SelfPreOtpContext = createContext({
  user: {
    username: "",
    type: "",
    id: "",
    otpEnable: "",
    roleName: undefined,
    role: null,
    permissions: {},
  },
  isProcessing: false,
  onSyncSelf: () => {},
});

export const SelfPreOtpContextProvider: FC<any> = ({ children }) => {
  const navigation = useNavigate();
  const contextAuth = useContext(AuthContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [user, setUser] = useState({
    username: "",
    type: "",
    id: "",
    otpEnable: "",
    roleName: undefined,
    role: null,
    permissions: {} as Permission,
  });

  const hasFetchedSelf = useRef(false);

  const onSyncSelf = useCallback(async () => {
    setIsProcessing(true);
    if (hasFetchedSelf.current) return;
    try {
      const token = contextAuth.token;
      if (!token) {
        setIsProcessing(false);
        return;
      }

      const request = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/authorization/self-pre-otp`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      const userData = {
        ...request.data.data,
        roleName: request.data.data.role.roleName,
        role: request.data.data.role,
        permissions: request.data.data.role.permissions,
      };

      setUser(userData);
      hasFetchedSelf.current = true;
    } catch (error) {
      const response = (error as any).response;

      console.error("Unexpected error", error);
    } finally {
      setIsProcessing(false);
    }
  }, [contextAuth]);

  return (
    <SelfPreOtpContext.Provider value={{ user, isProcessing, onSyncSelf }}>
      {children}
    </SelfPreOtpContext.Provider>
  );
};
