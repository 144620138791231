import { lazy } from "react";
import { Outlet } from "react-router";
import { RoleGuard } from "../contexts/RoleGuard";
import { AuthGuard } from "../utils/AuthGuard";
import { ScreenLoader } from "../utils/ScreenLoader";

//Promotion
const PromotionAdd = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionAdd")),
);
const PromotionReport = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionReport")),
);
const PromotionManagement = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionManagement")),
);
const PromotionEdit = ScreenLoader(
  lazy(() => import("../screens/promotion-screen/PromotionEdit")),
);

export const PromotionRoute = () => ({
  path: "promotion",
  element: (
    <AuthGuard>
      <Outlet />
    </AuthGuard>
  ),
  children: [
    {
      path: "promotion-add",
      element: (
        <RoleGuard
          requiredPermission="promotionManagement"
          requiredActions={["isCreate", "isRead"]}
        >
          <PromotionAdd />
        </RoleGuard>
      ),
    },
    {
      path: "promotion-report",
      element: (
        <RoleGuard
          requiredPermission="promotionReport"
          requiredActions={["isRead"]}
        >
          <PromotionReport />
        </RoleGuard>
      ),
    },
    {
      path: "promotion-management",
      element: (
        <RoleGuard
          requiredPermission="promotionManagement"
          requiredActions={["isRead"]}
        >
          <PromotionManagement />
        </RoleGuard>
      ),
    },
    {
      path: "promotion-edit/:id",
      element: (
        <RoleGuard
          requiredPermission="promotionManagement"
          requiredActions={["isUpdate", "isRead"]}
        >
          <PromotionEdit />
        </RoleGuard>
      ),
    },
  ],
});
